import { createGlobalStyle } from "styled-components";
// import { colors } from "./variables";
import bgHeader from "../assets/images/background.png";
import MontserratExtraBold from "../assets/fonts/Montserrat-ExtraBold.ttf";
import MontserratBold from "../assets/fonts/Montserrat-Bold.ttf";
import MontserratSemiBold from "../assets/fonts/Montserrat-SemiBold.ttf";
import MontserratMedium from "../assets/fonts/Montserrat-Medium.ttf";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Montserrat Extra Bold;
    font-style: normal;
    font-weight: 900;
    src: local("Montserrat Extra Bold"), local("Montserrat Extra Bold"),
      url("${MontserratExtraBold}") format("truetype");
  }
  @font-face {
    font-family: Montserrat Bold;
    font-style: normal;
    font-weight: 800;
    src: local("Montserrat Bold"), local("Montserrat Bold"),
      url("${MontserratBold}") format("truetype");
  }
  @font-face {
    font-family: Montserrat Semi Bold;
    font-style: normal;
    font-weight: 700;
    src: local("Montserrat Semi Bold"), local("Montserrat Semi Bold"),
      url("${MontserratSemiBold}") format("truetype");
  }
  @font-face {
    font-family: Montserrat Medium;
    font-style: normal;
    font-weight: 500;
    src: local("Montserrat Medium"), local("Montserrat Medium"),
      url("${MontserratMedium}") format("truetype");
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
  }
  .space{
    height:55px;
  }
  /* body{
    background: url('${bgHeader}') top right no-repeat;
    background-size: 100%;
  } */
  @media (max-width:768px){
    /* body{
      background: url('${bgHeader}') top right no-repeat;
      background-size: 150%;
    } */
    .m-Dnone{
      background:none;
      border:none;
    }
  }
  @media (max-width:480px){
    /* body{
      background: url('${bgHeader}') top right no-repeat;
      background-size: 250%;
    } */
  }

  .spinnerTime{
    position: relative;
    height: 100px;
    width: 100px;
    border: 3px solid transparent;
    border-top-color: #6773E5;
    margin: -30px;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    &:before,
    &:after {
      content: "";
      position: absolute;
      border: 3px solid transparent;
      border-radius: 50%;
    }
    &:before {
      border-top-color: #00AAAB;
      top: -14px;
      left: -14px;
      right: -14px;
      bottom: -14px;
      animation: spin 3s linear infinite;
    }
    &:after {
      border-top-color: #C5C6C8;
      top: 7px;
      left: 7px;
      right: 7px;
      bottom: 7px;
      animation: spin 4s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

`;

export default GlobalStyles;
