import React from "react";
import styled from "styled-components";

const ButtonCloseCont = styled.div`
  height:55px;
  .buttonClose {
    position: ${(props) => (props.stateButton ? "fixed" : "absolute")};
    height: 20px;
    right: 10%;
    top: 17px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:focus{
      outline:none;
    }
    .line {
      width: 22px;
      height: 1px;
      background: ${e => e.color ? '#000' : '#fff'};
      display: block;
      transition: ease-in-out 0.25s;
    }
  }
  @media (max-width: 768px){
    .buttonClose{
      right:5%;
    }
  }
  .off {
    .l1 {
      transform: translate(0px, 0px) rotate(0deg);
      margin-left: auto;
    }
    .l2 {
      transform: translate(0px, 8px) rotate(0deg);
      width: 11px;
      opacity: 1;
      margin-left: auto;
    }
    .l3 {
      transform: translate(0px, 16px) rotate(0deg);
      margin-left: auto;
    }
  }
  .on {
    position: fixed;
    z-index: 99;
    .l1 {
      transform: translate(0px, 9px) rotate(45deg);
      background: black;
      margin-left: auto;
    }
    .l2 {
      transform: translate(0, 10px) rotate(0deg);
      width: 11px;
      opacity: 0;
      margin-left: auto;
      background: black;
    }
    .l3 {
      transform: translate(0px, 7px) rotate(-45deg);
      background: black;
      margin-left: auto;
    }
  }
  @media (max-width: 1024px) {
    .buttonClose {
      position: fixed;
      .line {
        background: white;
      }
    }
    .on {
      .l1 {
        background: black;
      }
      .l2 {
        background: black;
      }
      .l3 {
        background: black;
      }
    }
  }
`;

const ButtonClose = ({ onClick, navigation, color }) => {
  return (
    <ButtonCloseCont stateButton={true} color={color}>
      <div className={`${navigation ? "on" : "off"} buttonClose`} onClick={onClick}  onKeyDown={onClick} role='button'  tabIndex="0">
        <span className="line l1"></span>
        <span className="line l2"></span>
        <span className="line l3"></span>
      </div>
    </ButtonCloseCont>
  );
};
export default ButtonClose;
