import React from 'react';
import GlobalStyles from '../styles/globalStyles'
import Navbar from './navbar'
import Footer from './footer';

const Layout = ({ children }) => {
  return(
    <>
      <GlobalStyles />
      <Navbar />
      {children}
      <Footer />
    </>
  )
};

export default Layout;