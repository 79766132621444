import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const Seo = (props) => {
  const { title, description, meta, lang } = props;
  const datos = useStaticQuery(
    graphql`
      query{
        site{
          siteMetadata{
            title
            description
            author
          }
        }
      }
    `
  )
  const metaDescription = description || datos.site.siteMetadata.description

  return <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={`%s | ${datos.site.siteMetadata.title}`}
    meta={[
      {
        name: "description",
        content: metaDescription
      },
      {
        name: "og:title",
        content: title
      },
      {
        name: "og:url",
        content: "https://www.myoblek.com/"
      },
      {
        name: "og:description",
        content: "La plataforma de monitoreo más potente de México"
      },
      {
        name: "og:type",
        content: "website"
      },
      {
        name: "og:locale",
        content: "es_ES"
      },
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:creatoe",
        content: datos.site.siteMetadata.author
      },
      {
        name: "twitter:title",
        content: title
      },
      {
        name: "twitter:description",
        content: description
      }
    ].concat(meta)}
  />;
};

export default Seo;

Seo.defaultProps = {
  lang: 'es',
  meta: [],
  description: ''
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
}
