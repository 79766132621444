import React from "react";
import { FooterContainer } from "../styles/components/footer.styled";
import wisotype from "../assets/brand/wisotype.svg";
import linkedin from "../assets/icons/linkedin.svg";
import instagram from "../assets/icons/instagram.svg";
import facebook from "../assets/icons/facebook.svg";
import twitter from "../assets/icons/twitter.svg";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";


const Footer = () => {
  return (
    <FooterContainer>
      <img src={wisotype} className="footer-logo" alt="isotype" />
      <div className="footer-content">
        <div className="social">
          <a href="https://www.linkedin.com/company/myoblek/about/">
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href="https://www.instagram.com/myoblek/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://www.facebook.com/MyOblek/" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="facebook" />
          </a>
          <a href="https://twitter.com/myoblek" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="twitter" />
          </a>
        </div>
        <Link to="/aviso" className="privacy-terms">AVISO DE PRIVACIDAD</Link>
        <Link to="/terminos" className="privacy-terms last-term">TÉRMINOS Y CONDICIONES</Link>
      </div>
      <p className="copyright">© 2020 OBLEK</p>
    </FooterContainer>
  );
};

export default Footer;
