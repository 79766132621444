import styled from 'styled-components'
import { device, colors } from '../variables'

export const FooterContainer = styled.footer`
display: flex;
flex-direction: column;
align-items: center;
background: ${colors.richBlack};
position:relative;
.footer-logo {
  width: 7rem;
  margin: 4rem 0;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .social {
    display: flex;
    justify-content: space-around;
    width: 27.5rem;
    padding: 0 2.5rem;
    margin-bottom: 3rem;
  }
  .privacy-terms {
    margin-bottom: 1rem;
    color: ${colors.white};
    font-size: 1.1rem;
    font-weight: 600;
  }
}
.copyright {
  margin: 2rem 0 2rem;
  color: ${colors.lightseaGreen};
  font-size: 1.1rem;
  font-weight: 600;
}
@media ${device.desktop} {
  .footer-content {
    flex-direction: row;
    justify-content: center;
    .social {
      order: 1;
      margin: 0 3rem;
      border-left: 0.1rem solid white;
      border-right: 0.1rem solid white;
    }
    .last-term {
      order: 2;
    }
    .privacy-terms {
      margin-bottom: 0;
    }
  }
}
`;