import React, { useState } from "react";
import { NavbarContainer, Menu } from '../styles/components/navbar.styled'
import wimagotype from "../assets/brand/wimagotype.svg";
import bimagotype from "../assets/brand/bimagotype.svg";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram,} from "react-icons/fa";
import ButtonClose from "./button-close";
import { window } from 'browser-monads'

const Navbar = () => {
  const [navigation, setNavigation] = useState(false);

  const pathName = window.location.pathname;
  return (
    <>
      <ButtonClose
        navigation={navigation}
        onClick={() => setNavigation(!navigation)}
        color={pathName === "/nosotros"}
      />
      <NavbarContainer bg={pathName === "/nosotros" ? true : false}>
        <a href="/">
          <img className="navbar-logo" src={pathName === "/nosotros" ?bimagotype : wimagotype} alt="imagotype" />
        </a>
        <div className='buttons'>
          <a href='https://app.myoblek.com/login' className='login'>Ingreso</a>
          <a href='https://app.myoblek.com/signup' className='signup'>Crear cuenta</a>
        </div>
      </NavbarContainer>
      <Menu open={navigation}>
        <div className="menu-header">
          <a onClick={() => setNavigation(!navigation)} href="/">
            <img className="menu-logo" src={bimagotype} alt="imagotype" />
          </a>
        </div>
        <div className="menu-navigation">
          <Link
            className="menu-anchor capitalize"
            onAnchorLinkClick={() => setNavigation(!navigation)}
            to="/#que-hacemos"
          >
            ¿Qué es MyOblek?
          </Link>
          <Link
            className="menu-anchor capitalize"
            onAnchorLinkClick={() => setNavigation(!navigation)}
            to="/#nosotros"
          >
            Nuestra Plataforma
          </Link>
          <Link
            className="menu-anchor capitalize"
            onAnchorLinkClick={() => setNavigation(!navigation)}
            to="/#precios"
          >
            Precios
          </Link>
          <Link
            className="menu-anchor capitalize"
            onAnchorLinkClick={() => setNavigation(!navigation)}
            to="/nosotros"
          >
            Nosotros
          </Link>
          <Link
            className="menu-anchor capitalize"
            onAnchorLinkClick={() => setNavigation(!navigation)}
            to="/#contact"
          >
            Contacto
          </Link>
          <Link
            className="menu-anchor capitalize"
            onClick={() => setNavigation(!navigation)}
            to="/faqs"
          >
            FAQ's
          </Link>
        </div>
        <div className="menu-social">
          <a href="https://www.facebook.com/MyOblek/" className="iconNetWork">
            <FaFacebookF />
          </a>
          <a href="https://twitter.com/myoblek" className="iconNetWork">
            <FaTwitter />
          </a>
          <a href="https://www.linkedin.com/company/myoblek/about/" className="iconNetWork">
            <FaLinkedinIn />
          </a>
          <a href="https://www.instagram.com/myoblek/" className="iconNetWork">
            <FaInstagram />
          </a>
        </div>
      </Menu>
    </>
  );
};

export default Navbar;
