import styled from 'styled-components'
import { device, center, colors, flexColumn, flexRow } from '../variables'

export const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  ${flexRow}
  align-items: center;
  justify-content:space-between;
  width: 100%;
  height: 55px;
  padding: 0 10%;
  background: ${e => e.bg ? 'white': colors.richBlack};
  box-shadow: ${e => e.bg && '0px 4px 4px #E8E8E8'};
  .buttons{
    ${flexRow}
    a{
      text-decoration:none;
      width:130px;
      height: 31px;
      border-radius:4px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      ${flexRow}
      align-items:center;
      justify-content:center;
    }
    .login{
      background: ${colors.purple};
      color: ${colors.white};
      margin-right:10px;
    }
    .signup{
      color: ${e => e.bg ? colors.richBlack : 'white'};
      margin-right:50px;
    }
  }
  .navbar-logo {
    width: 150px;
  }
  @media (max-width:720px){
    padding: 0 5%;
    .buttons{
      display:none;
    }
  }
`;

export const WhiteNavbarContainer = styled.nav`
display: none;
@media ${device.desktop} {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  ${flexRow}
  align-items: center;
  width: 100vw;
  height: 5.5rem;
  padding: 0 5%;
  background: ${colors.white};
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  .navbar-logo {
    width: 15rem;
  }
}
`;

export const Menu = styled.div`
${flexColumn}
${center}
position: fixed;
top: 0;
left: 0;
z-index: 15;
width: 100vw;
height: 100vh;
background: ${colors.white};
transform: ${(props) => (props.open ? "translateX(0)" : "translateX(-100%)")};
transition: transform 0.3s ease-in-out;
.menu-header {
  ${flexRow}
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 5.5rem;
  padding: 0 5%;
  .burguer {
    position: absolute;
    height: 1.5rem;
    right: 5%;
  }
  & .line {
    width: 22px;
    height: 1px;
    background: ${(props) => (props.open ? "black" : "rgba(0,0,0,0)")};
    display: block;
    transition: ease-in-out 0.25s;
  }
  & .off {
    opacity: 0;
  }
  & .on {
    position: fixed;
    .l1 {
      transform: translate(-3px, 5px) rotate(45deg);
    }
    .l2 {
      transform: translate(0, 10px) rotate(0deg);
      width: 11px;
      opacity: 0;
      margin-left: auto;
    }
    .l3 {
      transform: translate(-3px, 3px) rotate(-45deg);
    }
  }
  .menu-logo {
    width: 15rem;
  }
  .burguer {
    position: absolute;
    right: 5%;
  }
}
.menu-navigation {
  ${flexColumn}
  ${center}
  .menu-anchor {
    margin-bottom: 4rem;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 46px;
    text-decoration: none;
    color: ${colors.richBlack};
    height: 40px;
  }
}
.menu-social {
  ${flexRow}
  justify-content: space-around;
  position: absolute;
  bottom: 3rem;
  width: 27.5rem;
  padding: 0 2.5rem;
  margin-bottom: 3rem;
  a {
    font-size: 24px;
  }
  .iconNetWork {
    ${flexRow}
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    text-decoration: none;
    margin-right: 20px;
    background: #0b0c10;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    &:hover {
      background: #00aaab;
    }
    svg {
      font-size: 18px;
    }
  }
}
@media (min-width: 1200px) {
  .capitalize {
    font-size: 4rem !important;
    transition: all 0.1s linear;
    &:hover {
      color: ${colors.lightseaGreen};
      font-size: 5rem !important;
    }
  }
}
`;
