export const sizes = {
    mobile: '480px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1440px'
  }
  
export const device = {
    mobile: `(min-width: ${sizes.mobile})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    desktop: `(min-width: ${sizes.desktop})`
  }
  
export const colors = {
    richBlack: '#0B0C10',
    gunMetal: '#1F2833',
    silverSand: '#C5C6C8',
    torquioseBlue: '#66FCF1',
    RGBTorquioseBlue: '102, 252, 241',
    lightseaGreen: '#00AAAB',
    lettersGrey: '#738495',
    arrowGrey: '#A1ADB7',
    btngreen: '#00AFAA',
    grey: '#748596',
    lightGrey: 'rgba(236, 236, 236, 0.25)',
    white: '#FFFFFF',
    purple: '#6873E5',
    lightBlueBg: '#EEF2FB'
  }
  
export const flexRow = `
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  `

export const flexColumn = `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
`

export const flexColumnReverse = `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
`

export const center = `
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`
  
export const content = `
  width: 90%;
  height: 100%;
  max-width: 1500px;
`
  
export const shadow = `
  box-shadow: 0 13px 13px -6px rgba(0,0,0,0.6);
`
